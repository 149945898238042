<template>
  <!-- 添加企业信息 -->
  <div class="p-b20" v-if="isLoad">
    <div class="p-l-t-r20">
      <span class="rem36 c-303133 f500">融资金额（元）</span>
      <p class="c-333333 rem56 fNormal mt8">{{ borrowInfo.applyAmt }}</p>
      <div class="mt24">
        <van-row>
          <van-col span="8">融资人姓名</van-col>
          <van-col span="16">{{ borrowInfo.userName }}</van-col>
        </van-row>
        <van-row>
          <van-col span="8">身份证号码 </van-col>
          <van-col span="16">{{ borrowInfo.maskIdNo }}</van-col>
        </van-row>
        <van-row>
          <van-col span="8">收款账户</van-col>
          <van-col span="16">{{ borrowInfo.huadaoWallet }}</van-col>
        </van-row>
        <van-row>
          <van-col span="8">付款账户</van-col>
          <van-col span="16" class="flex just-end align-center" v-if="!addShow">
            <img class="img14 mr4" :src="addPng" @click="addAccount" />
            <span class="c-4D7BFE" @click="addAccount">添加付款账户</span>
          </van-col>
          <van-col span="16" v-else
            >{{ borrowInfo.lcApplAccInfoVO.applAcNam }}&nbsp;({{
              borrowInfo.lcApplAccInfoVO.applAcNo
            }})</van-col
          >
        </van-row>
        <van-row>
          <van-col span="8">融资期限</van-col>
          <van-col span="16"
            >{{ borrowInfo.applyTnr }}{{ borrowInfo.applyTnrTyp == 'M' ? '个月' : '天' }}</van-col
          >
        </van-row>
        <van-row v-if="borrowInfo.annualIntRate">
          <van-col span="8"> 综合年化利率 </van-col>
          <van-col span="16" class="flex just-end align-center">
            <span>{{ borrowInfo.annualIntRate }}%</span>
            <img
              :src="topPng"
              alt=""
              class="img14"
              v-if="!showMoreFlag"
              @click="changeFlag(true)"
            />
            <img :src="bottomPng" alt="" class="img16" v-else @click="changeFlag(false)" />
          </van-col>
        </van-row>
        <div v-if="showMoreFlag">
          <van-row v-if="borrowInfo.intRat">
            <van-col span="8" class="flex align-center">
              <span>日利率</span>
            </van-col>
            <van-col span="16">{{ borrowInfo.intRat }}%</van-col>
          </van-row>
          <van-row v-if="borrowInfo.serviceCharge">
            <van-col span="8" class="flex align-center">
              <span>手续费</span>
            </van-col>
            <van-col span="16">{{ borrowInfo.serviceCharge || 0 }}</van-col>
          </van-row>
        </div>
        <van-row>
          <van-col span="15">付款日（以实际放款为准）</van-col>
          <van-col span="9">{{ borrowInfo.dueDay }}</van-col>
        </van-row>
        <van-row>
          <van-col span="8">付款计划</van-col>
          <van-col span="16" class="flex just-end align-center" @click="repayShow">
            <span>首期付¥{{ psInstmAmount }}元</span>
            <img :src="morePng" alt="" class="img16" />
          </van-col>
        </van-row>
        <van-row>
          <van-col span="8">贷款发放人</van-col>
          <van-col span="16">{{ borrowInfo.daishouMerNo || '' }}</van-col>
        </van-row>
      </div>
    </div>
    <div class="flex align-center mt14 p-l20">
      <img :src="notChoosePng" alt="" @click="agreeFn(true)" v-if="!agreement" class="mr4 img12" />
      <img :src="choosedPng" alt="" @click="agreeFn(false)" v-else class="mr4 img12" />
      <span class="readed rem26"
        >我已阅读并同意签署<span class="c-4D7BFE" @click="seeContract">列表</span>中的合同文件</span
      >
    </div>
    <div class="mt36 p-l-r20">
      <van-button
        round
        block
        type="info"
        native-type="submit"
        :disabled="!agreement"
        @click="withdrawConfirm"
        >确认融资</van-button
      >
    </div>
    <div class="mt16 p-l-r20">
      <van-button round plain block type="primary" color="#4D7BFE" @click="cancelApply"
        >取消融资</van-button
      >
    </div>
    <repayment-plan ref="repayment" :repaymentLoan="repaymentLoan"></repayment-plan>
  </div>
</template>
<script>
import Vue from 'vue'
import { Col, Row, Button } from 'vant'

import {
  queryBillDetailApi,
  trialLoanRepayPlanApi,
  withdrawConfirmApi,
  cancelApplyApi,
  merchantRouteApi,
} from '@/apis/borrow'
import { getTempCodeApi } from '@/apis/credit'
import { mapState } from 'vuex'
import repaymentPlan from '@/components/repayment-plan.vue'
import loadingIndex from '@/utils/overlay'
Vue.use(loadingIndex)

Vue.use(Col)
Vue.use(Row)
Vue.use(Button)

export default {
  components: {
    repaymentPlan,
  },
  data() {
    return {
      agreement: false, // 协议
      showMoreFlag: false, // 利率
      notChoosePng: require('@/assets/images/index/notChoose.png'),
      choosedPng: require('@/assets/images/index/choosed.png'),
      tipsPng: require('@/assets/images/index/tips.png'),
      addPng: require('@/assets/images/borrow/add2.png'),
      topPng: require('@/assets/images/borrow/top-icon.png'),
      bottomPng: require('@/assets/images/borrow/bottom-icon.png'),
      morePng: require('@/assets/images/borrow/more.png'),
      borrowInfo: {},
      addShow: false, // 控制添加付款账户按钮
      repaymentLoan: {},
      isLoad: false,
      psInstmAmount: '', // 首期还
      seqNo: '',
    }
  },
  created() {
    this.queryBillDetail()
    this.trialLoanRepayPlan()
  },
  computed: {
    ...mapState({
      loanNo: (state) => state.borrow.loanNo,
      faceToken: (state) => state.borrow.faceToken,
      productCode: (state) => state.common.productCode,
      applySeq: (state) => state.borrow.applySeq,
      businessType: (state) => state.common.businessType,
    }),
  },
  methods: {
    agreeFn(flag) {
      if (!this.$route.params.isReadFinish) {
        this.$common.toast('请阅读并同意本协议！')
        return
      }
      this.agreement = flag
    },

    changeFlag(flag) {
      this.showMoreFlag = flag
    },
    addAccount() {
      this.$router.push({
        path: '/add-back-info',
        query: {
          bindType: this.borrowInfo.daishouMerNoCode,
        },
      })
    },
    // 获取merchantRoute
    merchantRoute() {
      merchantRouteApi({
        companyCode: this.borrowInfo.daishouMerNoCode,
        businessType: this.businessType,
      }).then((res) => {
        if (res.code == 200) {
          this.$store.commit('SET_MERCHANT_ROUTE', res.data)
        }
      })
    },
    // 获取贷款品种初始化信息
    queryBillDetail() {
      queryBillDetailApi({
        loanNo: this.loanNo,
        productCode: this.productCode,
      }).then((res) => {
        if (res.code === 200) {
          this.borrowInfo = res.data
          if (this.borrowInfo.daishouMerNoCode) this.merchantRoute()
          this.seqNo = res.data.lcApplAccInfoVO.seqNo
          this.addShow = this.borrowInfo.lcApplAccInfoVO.applAcNo ? true : false
          this.isLoad = true
        }
      })
    },
    // 查看合同
    seeContract() {
      getTempCodeApi({ loanNo: this.loanNo }).then((res) => {
        if (res.code === 200) {
          this.$router.push({
            path: '/view-contract',
            query: {
              file: JSON.stringify(res.data),
              type: 'borrow',
              pagePath: '/confirm-borrow',
            },
          })
        }
      })
    },
    // 付款试算
    trialLoanRepayPlan() {
      trialLoanRepayPlanApi({
        trailScene: 2,
        loanNo: this.loanNo,
      }).then((res) => {
        if (res.code === 200) {
          this.repaymentLoan = res.data
          if (res.data.shdList) this.psInstmAmount = this.repaymentLoan.shdList[0].psInstmAmount
        }
      })
    },
    repayShow() {
      this.$refs.repayment.borrowShow = true
    },
    // 取消融资
    cancelApply() {
      cancelApplyApi(this.loanNo).then((res) => {
        if (res.code === 200) {
          this.$router.push({ path: this.$store.state.common.defaultIndex })
          this.$store.commit('SET_LOANNO', '')
        }
      })
    },
    showloading() {
      var title = '加载中···'
      this.$showLoading({
        title: title,
      })
    },

    hideloading() {
      this.$cancelLoading()
    },
    // 确认融资
    withdrawConfirm() {
      if (!this.borrowInfo.lcApplAccInfoVO.applAcNo) {
        this.$common.toast('请添加付款账户')
        return
      }
      this.showloading()
      withdrawConfirmApi({
        loanNo: this.loanNo,
        bankCardNo: this.seqNo,
      })
        .then((res) => {
          if (res.code === 200) {
            this.$router.push({
              path: '/approvel-pass-result',
              query: {
                loan: JSON.stringify(res.data),
              },
            })
          } else {
            this.$router.push({
              path: '/approvel-refuse-result',
            })
          }
          this.hideloading()
        })
        .catch(() => {
          this.hideloading()
        })
    },
  },
}
</script>
<style lang="less" scoped>
.van-col--16,
.van-col--9 {
  text-align: right;
  color: #333;
  font-size: 0.3rem;
  font-weight: normal;
}
.van-col--8,
.van-col--15 {
  color: #303133;
  font-size: 0.3rem;
  font-weight: 500;
}
.van-col {
  margin-bottom: 0.4rem;
}
.readed {
  color: #b0b0b0;
}
</style>
